
import {
  collection,
  addDoc
} from "firebase/firestore"
import db from '@/main'

import axios from 'axios';
import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'Callback',
  data() {
    return {
      quotationForm: {
        fullName: "",
        email: "",
        mobile: "",
				// nationality: "",
				// service: "",
				// companyType: "",
				companyName: "",
        message: ""
      },
      showLoader: false,
      success: true,
      docRefId: ''
    }
  },

  methods: {
    async submitQuotationForm() {
      console.log("submitting/...");
      this.showLoader = true;
      await axios
        .post("/server/contact.php", this.quotationForm)
        .then((res: any) => {
          console.log("Response: ", res);
          this.resetQuotationForm();
          this.docRefId = "1";
          this.showLoader = false;
        });

      // this.showLoader = true;
      // const colRef = collection(db, 'Quotations');
      // const docRef = await addDoc(colRef, this.quotationForm);
      // if (docRef.id) {
      //   this.showLoader = false;
      //   this.resetQuotationForm();
      //   this.docRefId = docRef.id;
      //   console.log('Document was created with ID:', docRef.id)
      // }


    },
    resetQuotationForm() {
      this.quotationForm.fullName = "";
      this.quotationForm.email = "";
      this.quotationForm.mobile = "";
			// this.quotationForm.nationality = "";
			// this.quotationForm.service = "";
			// this.quotationForm.companyType = "";
			this.quotationForm.companyName = "";
			this.quotationForm.message = "";
    }
  }
});
