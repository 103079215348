<template>

    <!-- Team Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
                <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Our Team</p>
                <h3 class="mb-5">Our Top Team of Finance and Accounting Experts</h3>
            </div>
            <div class="row g-4">
                <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="d-flex justify-content-center justify-items-center flex-column">
                        <img class="img-fluid rounded shadow m-auto" src="/img/team-2.jpeg" alt="">
                        <div class="text-justify">
                            <div class="text-center">
                                <h4 class="mt-3 text-primary">CMA INDIRA GHOSH</h4>
                                <h6 class="">MCOM, FCMA</h6>
                            </div>
                            <div>
                                <p>
                                    Indira is a fellow member of the Institute of Cost Accountants of India (ICWA) and
                                    also holds a masters degree in business and commerce (MCOM) from the University of
                                    Calcutta, India.
                                </p>
                                <p>
                                    She has long work experience of more than 20+ years with different large and medium
                                    sized industries in the Gulf and worked with Companies like Ali Bin Ali , Exxon
                                    Mobil in Qatar. She also worked as head of a medium size manufacturing company
                                    engaged in the production of Industrial equipments in Dubai for more than 16 years.
                                    She is very sincere, compassionate, and is greatly devoted to her job.
                                </p>
                                <p>
                                    She has undertaken VAT training courses conducted by the Institute of Chartered
                                    Accountants of India, Dubai Chapter in 2017.
                                </p>
                                <p>
                                    Member -The Institute of Cost Accountants of India:
                                    <a href="https://icmai.in/">https://icmai.in/</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="d-flex justify-content-center justify-items-center flex-column">
                        <img class="img-fluid rounded shadow m-auto" src="/img/team-1.jpeg" alt="">
                        <div class="text-justify">
                            <div class="text-center">
                                <h4 class="mt-3 text-primary">CMA SAMIR GHOSH</h4>
                                <h6 class="">BSC (HONS), LLB, FCMA</h6>
                            </div>
                            <div>
                                <p>
                                    Samir is a fellow member of the Institute of The Cost Accountants of India (ICWA) in
                                    addition to having a Law degree (LLB) from the University of Calcutta, India.

                                </p>
                                <p>
                                    He has a very long hands on practical experience of work with different industries.
                                    He has worked for more than 23 years in the Gulf in different industries at senior
                                    levels ,apart from working with PWC in India , one of the big 4 audit firms in the
                                    world . While working in different industries he was involved in ERP implementation,
                                    preparation of project reports, feasibility studies, negotiation with banks for
                                    project loans, working capital loans, LC negotiation and handling imports and
                                    exports etc. and also responsible for finalization of accounts, internal audit etc.
                                </p>
                                <p>
                                    Samir knows the nuts and bolts of establishing a successful business and handholding
                                    the management in different practical aspects of business.
                                </p>
                                <p>
                                    While working with PWC in India and he was involved with audit of large and medium
                                    size Indian companies and MNCs , Banks etc.
                                </p>
                                <p>
                                    Samir also participated in VAT training conducted by the Institute Chartered
                                    institute of India, Dubai, and involved in submission of VAT returns & related
                                    services.
                                </p>
                                <p>
                                    Member -The Institute of Cost Accountants of India:
                                    <a href="https://icmai.in/">https://icmai.in/</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Team End -->

</template>

<style>

</style>
