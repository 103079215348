<template>

  <!-- Service Start -->
  <div class="container-xxl service py-5">
    <div class="container">
      <div
        class="text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 600px;"
      >
        <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Our Services</p>
        <h1 class="display-5 mb-5">We are happy to help with our Awesome Services</h1>
      </div>
      <div
        class="row g-4 wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <div class="col-lg-4">
          <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
            <button v-for="service, idx in $store.state.services" v-bind:key="idx" class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4" :class="{'active' : idx === 0}" data-bs-toggle="pill" :data-bs-target="`#tab-pane-${idx}`" type="button">
              <h5 class="m-0"><i class="fa fa-bars text-primary me-3" />{{service.name}}</h5>
            </button>
            <!-- <button

class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"

data-bs-toggle="pill"

data-bs-target="#tab-pane-2"

type="button"

>

<h5 class="m-0"><i class="fa fa-bars text-primary me-3" />Cash Investment</h5>

</button>

<button

class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"

data-bs-toggle="pill"

data-bs-target="#tab-pane-3"

type="button"

>

<h5 class="m-0"><i class="fa fa-bars text-primary me-3" />Financial Consultancy</h5>

</button>

<button

class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"

data-bs-toggle="pill"

data-bs-target="#tab-pane-4"

type="button"

>

<h5 class="m-0"><i class="fa fa-bars text-primary me-3" />Business Loans</h5>

</button> -->
          </div>
        </div>
        <div class="col-lg-8">
          <div class="tab-content w-100">
            <div
              v-for="service, idx in $store.state.services"
              v-bind:key="idx"
              class="tab-pane fade "
              :class="{'show active' : idx === 0}"
              :id="`tab-pane-${idx}`"
            >
              <div class="row g-4">
                <div
                  class="col-md-6"
                  style="min-height: 350px;"
                >
                  <div class="position-relative h-100">
                    <img class="position-absolute rounded w-100 h-100" :src="service.image" style="object-fit: cover;" alt="" />
                  </div>
                </div>
                <div class="col-md-6">
                  <h3 class="mb-4">{{service.title}}</h3>
                  <p
                    v-for="desc in service.description"
                    v-bind:key="desc.id"
                    class="mb-3"
                  >
                    {{desc}}
                  </p>
                  <p v-for="item, idx in service.list" v-bind:key="idx">
                    <i class="fa fa-check text-primary" />
                    {{idx+1}}. {{item}}
                  </p>
                  <router-link
                    :to="`/services/${service.slug}`"
                    class="btn btn-primary py-3 px-5 mt-3"
                  >Read More</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service End -->
</template>
