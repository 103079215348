import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_about = _resolveComponent("about")!
  const _component_features = _resolveComponent("features")!
  const _component_services = _resolveComponent("services")!
  const _component_callback = _resolveComponent("callback")!
  const _component_team = _resolveComponent("team")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hero),
    _createVNode(_component_about),
    _createVNode(_component_features),
    _createVNode(_component_services),
    _createVNode(_component_callback),
    _createVNode(_component_team)
  ]))
}