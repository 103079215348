
import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    msg: String,
  },
});
