
import {
  collection,
  addDoc
} from "firebase/firestore"
import db from '@/main'

import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'Footer',
  data() {
    return {
      newsletterForm: {
        email: ""
      },
      showLoader: false,
      success: true,
      docRefId: ''
    }
  },
  methods: {
    async submitNewsletterForm() {
      this.showLoader = true;
      const colRef = collection(db, 'Newsletters');
      const docRef = await addDoc(colRef, this.newsletterForm);
      if (docRef.id) {
        this.showLoader = false;
        this.resetNewsletterForm();
        this.docRefId = docRef.id;
        console.log('Document was created with ID:', docRef.id)
      }
    },
    resetNewsletterForm() {
      this.newsletterForm.email = "";
    }
  }
});
