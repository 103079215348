<template>
  <div class="hero-carousel">

      <!-- Carousel Start -->
      <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
          <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
              <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img class="w-100" src="img/carousel-1.jpg" alt="Image" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-start">
                                  <div class="col-lg-8">
                                      <p
                                          class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown"
                                      >
                                          Welcome to WhizPro
                                      </p>
                                      <h1 class="mb-3 animated slideInDown">
                                          We are committed to providing the best accounting service possible
                                          
                                      </h1>
                                      <h4 class="mb-4">
                                          Accounting,  Bookkeeping and VAT Advisory Services
                                      </h4>
                                      <router-link to="/services" class="btn btn-primary py-3 px-5 animated slideInDown">Explore More</router-link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="carousel-item">
                      <img class="w-100" src="img/carousel-2.jpg" alt="Image" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-start">
                                  <div class="col-lg-7">
                                      <p
                                          class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown"
                                      >
                                          Welcome to WhizPro
                                      </p>
                                      <h1 class="mb-4 animated slideInDown">
                                          We are now Zoho Authorized Partner
                                      </h1>
                                      <router-link to="/zoho" class="btn btn-primary py-3 px-5 animated slideInDown">Explore More</router-link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#header-carousel"
                  data-bs-slide="prev"
              >
                  <span class="carousel-control-prev-icon" aria-hidden="true" />
                  <span class="visually-hidden">Previous</span>
              </button>
              <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#header-carousel"
                  data-bs-slide="next"
              >
                  <span class="carousel-control-next-icon" aria-hidden="true" />
                  <span class="visually-hidden">Next</span>
              </button>
          </div>
      </div>
      <!-- Carousel End -->
  </div>
</template>

<style>

/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 3rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--primary);
    border: 10px solid var(--primary);
}

.carousel-control-prev-icon {
    border-radius: 0 3rem 3rem 0;
}

.carousel-control-next-icon {
    border-radius: 3rem 0 0 3rem;
}

/* #header-carousel .carousel-item {
        position: relative;
        min-height: 550px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */
@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 550px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

</style>