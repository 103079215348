<template>

  <!-- Features Start -->
  <div class="container-xxl feature py-5">
    <div class="container">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Why Choosing Us!</p>
          <h1 class="display-5 mb-4">Few Reasons Why People Choosing Us!</h1>
          <p class="mb-3">Here are significant advantages for clients who decide to outsource their accounting tasks.
            To begin with, cost-efficiency, and ending with flexibility in terms of contracts or scaling the number of
            functions.
            Accounting and bookkeeping are a vital part of business, but also complex. It requires time and specific
            knowledge. Without
            accounting experience, there might occur many errors which can lead to a decrease in your business or even
            go bankrupt.

          </p>
          <p class="mb-4">We are a reliable accounting service provider to small and medium-sized businesses.
          </p>

          <router-link class="btn btn-primary py-3 px-5" to="/contact">Contact Us</router-link>
        </div>
        <div class="col-lg-6">
          <div class="row g-4 align-items-center">
            <div class="col-md-6">
              <div class="row g-4">
                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div class="feature-box border rounded p-4">
                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Experienced Team</h4>
                    <p class="mb-3">We act and operate as your representative office in the Middle
                      East region, offering all the benefits of a physical office and a highly qualified
                      and experienced team of consultants available for you.</p>
                    <!-- <a class="fw-semi-bold" href="">Read More <i
                                                class="fa fa-arrow-right ms-1"></i></a> -->
                  </div>
                </div>
                <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div class="feature-box border rounded p-4">
                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Guide & Support</h4>
                    <p class="mb-3">Our qualified accounting professionals and our
                      experience with various packages currently on the market as well
                      as awareness of new products allow us to help you in choosing and implementing
                      a system that will suit your company’s needs</p>
                    <!-- <a class="fw-semi-bold" href="">Read More <i
                                                class="fa fa-arrow-right ms-1"></i></a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div class="feature-box border rounded p-4">
                <i class="fa fa-check fa-3x text-primary mb-3"></i>
                <h4 class="mb-3">Financial Secure</h4>
                <p class="mb-3">WhizPro can provide an Safe , Secure and reliable function to your entity to report to
                  directors regarding the right workflow and fix responsibility and accountability of employees in the
                  organization.</p>
                <!-- <a class="fw-semi-bold" href="">Read More <i class="fa fa-arrow-right ms-1"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Features End -->

</template>
