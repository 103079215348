
import { defineComponent } from 'vue';
import Hero from '@/components/Hero.vue'; // @ is an alias to /src
import About from '@/components/About.vue'; 
// import Facts from '@/components/Facts.vue'; 
import Features from '@/components/Features.vue'; 
import Services from '@/components/Services.vue'; 
import Callback from '@/components/Callback.vue'; 
import Team from '@/components/Team.vue'; 
// import Projects from '@/components/Projects.vue'; 

export default defineComponent({
  name: 'HomeView',
  components: {
    Hero,
    About,
    // Facts,
    Features,
    Services,
    Callback,
    Team
    // Projects
  },
});
