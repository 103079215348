<template>

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-4 align-items-start mb-4">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <img class="img-fluid rounded shadow" src="img/about.jpg">
        </div>
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">About Us</p>
          <h1 class="display-5 mb-4">We are ready to assist you in all your Accounting Needs.</h1>
          <p class="mb-4">WhizPro is committed to providing the best accounting service possible on a timely basis with personal service to each client, helping them with their plans and being available to help and solve simple, as well as complex problems.
          </p>
          <div class="rounded p-4 shadow-sm">
            <nav>
              <div
                class="nav nav-tabs mb-3"
                id="nav-tab"
                role="tablist"
              >
                <button class="nav-link fw-semi-bold active" id="nav-story-tab" data-bs-toggle="tab" data-bs-target="#nav-story" type="button" role="tab" aria-controls="nav-story" aria-selected="true">Story</button>
                <button class="nav-link fw-semi-bold" id="nav-mission-tab" data-bs-toggle="tab" data-bs-target="#nav-mission" type="button" role="tab" aria-controls="nav-mission" aria-selected="false">Mission</button>
                <button class="nav-link fw-semi-bold" id="nav-vision-tab" data-bs-toggle="tab" data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision" aria-selected="false">Vision</button>
              </div>
            </nav>
            <div
              class="tab-content"
              id="nav-tabContent"
            >
              <div
                class="tab-pane fade show active"
                id="nav-story"
                role="tabpanel"
                aria-labelledby="nav-story-tab"
              >
                <p>WhizPro accounting is an accounting and bookkeeping firm established in Dubai. It is managed
                  by professionally qualified Cost and Management Accountant having long practical experience working at senior
                  levels in different industries in the gulf, and also audit experience with one of the four biggest audit firms in the world,
                  along with a team of well qualified experts in the relevant fields, for providing various services to clients in the Fields of
                  Accounting and book keeping, CFO services, feasibility studies, VAT advisory and Management Consultancy etc.</p>
                <!-- <p class="mb-0">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.
                                    Clita erat ipsum et lorem et sit</p> -->
              </div>
              <div
                class="tab-pane fade"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <p>WhizPro is committed to providing the best accounting service possible on a timely basis with personal service to each client,
                  helping them with their plans and being available to help and solve simple, as well as complex problems..</p>
                <!-- <p class="mb-0">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.
                                    Clita erat ipsum et lorem et sit</p> -->
              </div>
              <div
                class="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <p>WhizPro is dedicated to offering the finest accounting service possible on a timely manner,
                  with personal care to each customer, assisting them with their plans, and being ready to help
                  and solve simple and difficult problems.</p>
                <!-- <p class="mb-0">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.
                                    Clita erat ipsum et lorem et sit</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="rounded p-4 wow fadeInUp shadow-sm"
        data-wow-delay="0.1s"
      >
        <div class="row g-4">
          <div
            class="col-lg-4 wow fadeIn"
            data-wow-delay="0.1s"
          >
            <div class="h-100">
              <div class="d-flex">
                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                  <i class="fa fa-times text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>No Hidden Cost</h4>
                  <span>All of our services are provided without any additional costs or taxes.</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
              </div>
              <div class="border-bottom mt-4 d-block d-lg-none"></div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeIn"
            data-wow-delay="0.3s"
          >
            <div class="h-100">
              <div class="d-flex">
                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                  <i class="fa fa-users text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>Dedicated Team</h4>
                  <span>We Have a team of Highly Experienced Proffessionals to provide you best Experience.</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
              </div>
              <div class="border-bottom mt-4 d-block d-lg-none"></div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeIn"
            data-wow-delay="0.5s"
          >
            <div class="h-100">
              <div class="d-flex">
                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                  <i class="fa fa-phone text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>24/7 Available</h4>
                  <span>Our Commited Customer service are available to Provide you Customer support whenever you Need.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->
</template>

<style>

</style>
