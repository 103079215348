
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
    Footer,
  },
});
